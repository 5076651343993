import { Component, OnInit} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
    ngOnInit(): void {
        
    }
    status : string
    constructor(public api : ApiService,
                public activatedRoute : ActivatedRoute,
                private router: Router){
                    this.activatedRoute.paramMap.subscribe((resp : any) => {
                        this.status = resp.get('status')
                        if(this.status == 'failed'){
                            setTimeout(()=> {
                                this.router.navigate(['/parallel/dashboard'])
                            } , 2000)
                           
                        }
                    }) 
                }
}
