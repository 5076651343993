import { apis } from 'src/environments/environment'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Router } from '@angular/router'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/project`
    private baseUrlForBalance = `${apis.baseUrl}/parallel`
    activeMenu = 'business'

    constructor(public http: HttpClient, private router: Router) {}

    
}
