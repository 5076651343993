<div class="signin-container" *ngIf="status == 'success'">
    <div class="signin-section">
        <div class="signin-inner">
            <div class="signin-heading">
                <div class="img-wrapper"><img src="/assets/images/check.png" alt=""></div>
                <h1 class="title">Payment Successful</h1>
                <p class="title-desc">Thank You! Your Payment is Complete</p>
                <p class="title-desc" routerLink="/parallel/dashboard">Back To <a>Home</a></p>
            </div>
        </div>
    </div>
</div>
<div class="signin-container" *ngIf="status == 'failed'">
    <div class="signin-section">
        <div class="signin-inner">
            <div class="signin-heading">
                <div class="img-wrapper"><img src="/assets/images/check.png" alt=""></div>
                <h1 class="title">Your payment has been canceled.....</h1>
                <p class="title-desc">Thank You!</p>
            </div>
        </div>
    </div>
</div>
